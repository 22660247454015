import React from "react";
import styled from "styled-components";
import {Colors} from "../../_theme/color";

export enum FontSize {
  Small = "13px",
  Middle = "17px",
  Large = "20px",
  ExtraLarge = "30px",
  BigLarge = "40px",
  Notice = "56px"
}

interface Bold {
  bold?: boolean;
}

interface LinkProps extends Bold {
  element?: string;
  color?: Colors;
}

const LegalTextPrimitive: React.FC<LinkProps | HeaderTextProps> = ({
  element = "span",
  ...props
}) => React.createElement(element, props);

const LegalText = styled(LegalTextPrimitive)<LinkProps>`
  color: ${({ color = Colors.GrayScale1 }) => color};
  font-size: ${FontSize.Small};
  font-family: 'Open Sans', serif;
  ${({ bold }) => (bold ? "font-weight: bold" : "")}
`;

interface HeaderTextProps extends Bold {
  color?: Colors;
  element?: string;
  type?: FontSize;
  dangerouslySetInnerHTML?: any;
  maxWidth?: number;
  margin?: string | number;
}

const HeaderText = styled(LegalTextPrimitive)<HeaderTextProps>`
  color: ${({ color = Colors.BlackText}) => color};
  font-size: ${({ type: fontSize = FontSize.Large }) => fontSize};
  max-width: ${({maxWidth}) => (maxWidth ? `${maxWidth}px` : "initial")};
  /* margin: ${({margin}) => (margin ? margin : 'initial')}; */
  font-family: 'Open Sans', serif;
  ${({ bold }) => (bold ? "font-weight: bold" : "")}
`;

export {
  LegalText,
  HeaderText,
};
