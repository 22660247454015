// Export enum Spacer.
export enum Spacer {
  space0 = "0",
  space1 = "6px",
  space2 = "12px",
  space3 = "18px",
  space4 = "24px",
  space5 = "30px",
  space6 = "36px",
  space7 = "42px",
  space8 = "48px",
  space9 = "54px",
  max = "1110px"
}
