import styled from "styled-components";
import {Spacer} from "../_theme/spacer";
import {MediaQuery} from "../_theme/media-query";
import Layout from "../_children/Layout/Layout.style";

const WrapCards = styled.div`
  display: inline-flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: space-between;
`

const WrapCard = styled.div`
  max-width: calc(33.3% - ${Spacer.space2});
  width: 100%;
  display: flex;
  margin-top: ${Spacer.space3};
  
  ${MediaQuery.mobile} {
    max-width: 100%;
    margin: ${Spacer.space2};
  }
`

const WrapContent = styled(Layout.Wrap)`
  background-color: #f8f9fa;
  padding: ${Spacer.space9} 0;
`

export {
  WrapCards,
  WrapCard,
  WrapContent
};
