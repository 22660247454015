// Export enum Colors.
export enum Colors {
  GrayBackground = "#f8f9fa",
  Yellow = "#ffca26",
  YellowHover = "#eebb20",
  BlueSky = "#007bff",
  Black = "#1b1c1d ",
  BlackText = '#212529',
  White = "#fff",
  GrayBasic = "#5a6268",
  GrayScale0 = "#fbfcfc",
  GrayScale1 = "#CCC",
  GrayLight = "#e2e3e4"
}
