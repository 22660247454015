import Card from "../../../_children/Card/Card";
import Layout from "../../../_children/Layout/Layout.style";
import {
  FontSize,
  HeaderText,
  LegalText,
} from "../../../_children/Typography/Typography";
import { Colors } from "../../../_theme/color";
import Button from "../../../_children/Button/Button";
import Link, { TypeLink } from "../../../_children/Link/Link.style";
import { WrapCard } from "../../Content.style";
import styled, { css } from "styled-components";
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons/faTimesCircle";
import { Spacer } from "../../../_theme/spacer";

interface ItemPackage {
  name: string;
  price: number;
}

export enum PackageType {
  AllInclusive = "paquete-todo-incluido",
  PackageDiaries = "paquete-diarios",
  PackageMagazines = "paquete-revistas",
  IndividualPackages = "paquetes-individuales",
}

export interface Package {
  action: PackageType;
  title: string;
  description: string;
  price: string;
  imageUrl: string;
  items: ItemPackage[];
  onSubscribe?: () => void;
  urlPackage: string;
  textHeader?: string;
}

const Image = styled.img`
  width: 100%;
`;

interface VeldProps {
  show?: boolean;
}

const Close = styled(Link)`
  position: absolute;
  top: ${Spacer.space2};
  right: ${Spacer.space2};
`;

const Veld = styled.div<VeldProps>`
  position: absolute;
  overflow-y: scroll;
  top: 0;
  background-color: #fff;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 30px;
  border-radius: 5px;
  transform: translate3d(0, 100%, 0);
  opacity: 0;
  transition: transform 300ms ease, opacity 300ms ease;

  ${({ show }) =>
    show
      ? css`
          transform: translate3d(0, 0, 0);
          opacity: 1;
        `
      : ""}
`;

const PackageCard = ({
  action,
  title,
  description,
  price,
  imageUrl,
  items,
  onSubscribe,
  textHeader,
}: Package) => {
  const [showVeld, setShowVeld] = useState(false);
  return (
    <WrapCard>
      <Card shadow={"medium"} type="hover">
        <Layout.Center>
          <HeaderText element="p" bold>
            {title}
          </HeaderText>
          <Image src={imageUrl} alt="" />
          <Layout.Center padding="0 10%">
            <HeaderText element="span" type={FontSize.ExtraLarge} bold>
              {" "}
              {action === "paquetes-individuales" ? "Desde" : ""} S/.{" "}
              {Number(price).toFixed(2)}
            </HeaderText>
            <HeaderText
              element="div"
              type={FontSize.Large}
              color={Colors.GrayBasic}
              bold
            >
              al mes
            </HeaderText>
            <br />
            <Button expand onClick={onSubscribe}>
              Suscribirme
            </Button>
            <HeaderText element="p" type={FontSize.Middle} dangerouslySetInnerHTML={{__html: description}}>
              {/* {description} */}
            </HeaderText>
            <Link onClick={() => setShowVeld(true)} typeLink={TypeLink.Blue}>
              Ver más
            </Link>
          </Layout.Center>
        </Layout.Center>

        <Veld show={showVeld}>
          <Close onClick={() => setShowVeld(false)}>
            <FontAwesomeIcon size="lg" color="#868787" icon={faTimesCircle} />
          </Close>
          <HeaderText bold>Lista de {textHeader}</HeaderText>
          <br />
          <br />
          <div style={{ columns: 2 }}>
            {items.map(({ name, price }) => (
              <div>
                <LegalText color={Colors.BlackText}>
                  {textHeader === "Diarios y Revistas"
                    ? `${name} - S/. ${price} al mes`
                    : name}
                </LegalText>
              </div>
            ))}
          </div>
        </Veld>
      </Card>
    </WrapCard>
  );
};

export default PackageCard;
