import React from "react";
import styled, { keyframes } from "styled-components";

export enum Shape {
  Default = "0",
  Round = "5px;",
}

export enum Size {
  Default = "16px;",
  Small = "12px;",
  Large = "22px;",
}

const animateLines = keyframes`
  from {
    background-color: #ededed;
  }
  to {
    background-color: #d4d4d4;
  }
`;

interface LineStyledInterface {
  shape?: Shape;
  size?: Size;
}

const LineStyled = styled.div<LineStyledInterface>`
  background-color: #ededed;
  height: ${({ size = Size.Default }) => size};
  margin: 8px;
  animation: ${animateLines} 1000ms alternate infinite;
  border-radius: ${({ shape = Shape.Default }) => shape};
`;

interface SkeletonProps {
  lines?: number;
  shape?: Shape;
  size?: Size;
}

const Skeleton: React.FC<SkeletonProps> = ({
  lines = 3,
  shape,
  size,
}: SkeletonProps) => {
  return (
    <div>{new Array(lines).fill(<LineStyled shape={shape} size={size} />)}</div>
  );
};

export default Skeleton;
