import React, { FC, ChangeEvent, useEffect, useState } from "react";
import Skeleton, { Shape, Size } from "../_children/Skeleton/Skeleton";
import { Packages, services } from "./_dependencies/services";
import Button, { Theme } from "../_children/Button/Button";
import Section from "./_children/Section/Section";
import CheckBox from "./_children/CheckBox/CheckBox";
import { FontSize, HeaderText } from "../_children/Typography/Typography";
import IndividualStyle from "./Individual.style";

const packageList = new Map();

interface PropsIndividuals {
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

const Individuals: FC<PropsIndividuals> = ({ setShow }: PropsIndividuals) => {
  const [packages, setPackages] = useState<Packages[]>([]);
  const [source, setSource] = useState<MessageEventSource | null>(null);
  const [origin, setOrigin] = useState("");
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    window.addEventListener("message", (event: MessageEvent) => {
      if (event.data === "broadcast" && event.source) {
        setSource(event.source);
        setOrigin(event.origin);

        const message = JSON.stringify({ response: "ready" });
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        event.source.postMessage(message, event.origin);
      }
    });
  }, []);

  useEffect(() => {
    const getPackage = async () => {
      setLoading(true);
      const packages = await services.getPackage();
      setPackages(packages);
      setLoading(false);
    };

    getPackage();
  }, []);

  const onSubmit = () => {
    setDisabled(true);
    const products = [...packageList].map(([productId, tisId]) => ({
      productId,
      tisId,
    }));

    if (source) {
      const message = JSON.stringify({ response: "products", products });
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      source.postMessage(message, origin);
    }

    let stringProducts = "";
    products.forEach(
      (product) =>
        (stringProducts =
          stringProducts + `&producto[${product.productId}]=${product.tisId}`)
    );
    // REVERT TO PROD - `${process.env.REACT_APP_SERVICE_BASEPATH}/paquetes/index/compra?${stringProducts}&suscripcion=debito`
    // TEST - `https://pre.peruquiosco.pe/paquetes/index/compra?${stringProducts}&suscripcion=debito`
    
    window.location.href = `${process.env.REACT_APP_SERVICE_BASEPATH}/paquetes/index/compra?${stringProducts}&suscripcion=debito`;
    setShow(false);
  };

  return (
    <IndividualStyle.IndividualWrap>
      <div className="individual-page__head">
        <span className="individual-page__head__title">
          Seleccionar las opciones
        </span>
      </div>
      <br />
      {loading ? (
        <>
          <Skeleton lines={1} shape={Shape.Round} size={Size.Large} />
          <br />
          <Skeleton lines={6} shape={Shape.Round} />
          <br />
          <br />
          <Skeleton lines={1} shape={Shape.Round} size={Size.Large} />
          <br />
          <Skeleton lines={6} shape={Shape.Round} />
        </>
      ) : (
        packages.map(({ categorie, options }) => (
          <div className="individual-page__card" key={categorie}>
            <Section key={categorie} title={categorie} />
            {options.map(({ prodId, prodNombre, tisPrecio, tisId }, index) => prodId === "37" ? null : (
              <CheckBox
                onChange={({ target }: ChangeEvent<HTMLInputElement>) => {
                  if (target.checked) {
                    setTotal(total + +tisPrecio);
                    packageList.set(prodId, tisId);
                  } else {
                    packageList.delete(prodId);
                    setTotal(total - +tisPrecio);
                  }
                  setDisabled(packageList.size === 0);
                }}
                id={prodId}
                label={
                  <HeaderText
                    type={FontSize.Middle}
                  >{`${prodNombre} - S/. ${tisPrecio} al mes`}</HeaderText>
                }
                key={`${prodId}-${index}`}
              />
            ))}
            <br />
          </div>
        ))
      )}

      <IndividualStyle.Divider />

      <HeaderText>{`Total: S/. ${total.toFixed(2)}`}</HeaderText>
      <br />
      <br />
      <Button disabled={disabled} expand onClick={onSubmit}>
        Comprar
      </Button>
    </IndividualStyle.IndividualWrap>
  );
};

export default Individuals;
