function AutoTest({ onClose }: any) {
  return (
    <div className="autoTest">
      <button className="autoTest__btnClose" onClick={() => onClose()}>
        Cerrar
      </button>

      <div className="containerTests">
        <iframe className="autoTest__iframe" src="https://dev.qa-automation.ec.pe/" />
      </div>
    </div>
  );
}

export default AutoTest;
