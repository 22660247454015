import {Spacer} from "../../_theme/spacer";
import {FontSize, HeaderText} from "../../_children/Typography/Typography";
import Card from "../../_children/Card/Card";
import React from "react";
import styled from "styled-components";
import {MediaQuery} from "../../_theme/media-query";

interface BenefitCardProps {
  title: string;
  description: string;
  icon: string;
}

const Image = styled.img`
  margin: ${Spacer.space5} ${Spacer.space2};
`;

const CardFeature = styled(Card)`
  ${MediaQuery.lessThanOrEqualToTablet} {
    flex-direction: column;
    
    img {
      margin-bottom: 0;
    }
  }
`;

const BenefitCard: React.FC<BenefitCardProps> = ({title, description, icon}) => {
  return (
    <CardFeature margin={Spacer.space3} flex>
      <div>
        <Image width="38" src={icon} alt=""/>
      </div>
      <div>
        <HeaderText element="p" type={FontSize.ExtraLarge} bold>
          {title}
        </HeaderText>
        <HeaderText element="p" type={FontSize.Middle}>
          {description}
        </HeaderText>
      </div>
    </CardFeature>
  )
}

export default BenefitCard;
