// Return list terms and conditions of footer.
export const siteFooter = [
  {
    text: "Términos y condiciones",
    href: "https://peruquiosco.pe/terminos-condiciones",
  },
  {
    text: "Políticas y Cookies",
    href: "https://peruquiosco.pe/politicas-cookies",
  },
  {
    text: "Libro de Reclamaciones",
    href: "http://ecomedia.pe/libro/inicio/elcomercio/",
  },
  // {
  //   text: "Preguntas Frecuentes",
  //   href: "https://peruquiosco.pe/preguntas-frecuentes",
  // },
  {
    text: "Lo nuevo en PerúQuiosco",
    href: "/",
  },
];
