import Layout from "../_children/Layout/Layout.style";
import Slider from "react-slick";
import React, { MouseEvent, useEffect, useState } from "react";
import {
  BackgroundArrow,
  ImgCover,
  WrapCover,
  WrapSlider,
  WrapCard,
} from "./Covers.style";
import { FontSize, HeaderText } from "../_children/Typography/Typography";
import Button, { Theme } from "../_children/Button/Button";
import styled from "styled-components";
import { coversList } from "../_dependencies/covers";
import { faNewspaper } from "@fortawesome/free-solid-svg-icons/faNewspaper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Link from "../_children/Link/Link.style";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons/faAngleLeft";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons/faAngleRight";
import { Colors } from "../_theme/color";
import { Spacer } from "../_theme/spacer";

const PrevArrow = styled(BackgroundArrow)`
  left: -40px;
`;
const NextArrow = styled(BackgroundArrow)`
  right: -40px;
  justify-content: center;
`;

const settings = {
  dots: true,
  infinite: true,
  autoplay: false,
  autoplaySpeed: 5000,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 5,
  arrows: true,
  prevArrow: (
    <PrevArrow>
      <FontAwesomeIcon color={Colors.White} icon={faAngleLeft} />
    </PrevArrow>
  ),
  nextArrow: (
    <NextArrow>
      <FontAwesomeIcon color={Colors.White} icon={faAngleRight} />
    </NextArrow>
  ),
  dotsClass: "slick-dots dots-custom",
  responsive: [
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 1023,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
  ],
};

// Creates a Covers interface.
interface Covers {
  title: string;
  link: string;
  img: string;
  token?: string;
}

// Redirect a user to a visor pressreader.
const Cover: React.FC<Covers> = ({ title, img, link, token = "" }: Covers) => {
  // Handle function a click for redirect visor with token user.
  const handleClick = () =>
    (window.location.href = `${link}${token ? `?token=${token}` : ""}`);

  return (
    <div onClick={handleClick} style={{ cursor: "pointer" }}>
      <WrapCover>
        {title === "Perú21" && (
          <div
            style={{
              backgroundColor: "#ff7f50",
              borderRadius: "10px 10px 0px 0px",
              color: "white",
              fontSize: "12px",
              padding: "3px 15px",
              fontWeight: 700,
            }}
          >
            Ediciones disponibles: <br />
            Ene-2020 a Ene-2023
          </div>
        )}
        <WrapCard padding="2px" type="hover">
          <ImgCover src={img} alt={title} />
          <Layout.Center>
            <HeaderText type={FontSize.Small} bold>
              {title}
            </HeaderText>
          </Layout.Center>
        </WrapCard>
      </WrapCover>
    </div>
  );
};

// NameCovers represents an enumeration of national magazines local and national names
enum NameCovers {
  National = "NATIONAL",
  Magazine = "MAGAZINE",
  Local = "LOCAL",
}

// Component slider of covers news national, local and magazine.
const SliderCovers = ({ user }: any) => {
  // Sets the current and active states of the covers.
  const [currentCovers, setCurrentCovers] = useState<Covers[]>([]);
  // Sets all covers to use state.
  const [allCovers, setAllCovers] = useState<Covers[][]>([]);
  // Sets the active cover to the state.
  const [activeCover, setActiveCover] = useState(NameCovers.National as string);

  // Sets all the covers of the current node.
  useEffect(() => {
    const [national] = coversList;
    setAllCovers(coversList);
    setCurrentCovers(national);
  }, []);

  // Sets the active cover and local cover.
  const callCovers = ({ target }: MouseEvent<HTMLButtonElement>) => {
    window.location.href = "#covers";
    const { name } = target as HTMLButtonElement;
    setActiveCover(name);
    const [national, regional, magazine] = allCovers;
    // Sets the current covers for the given name.
    switch (name) {
      case NameCovers.Magazine:
        setCurrentCovers(magazine);
        break;
      case NameCovers.Local:
        setCurrentCovers(regional);
        break;
      default:
        setCurrentCovers(national);
        break;
    }
  };
  // return covers
  return (
    <>
      <Layout.Wrap>
        <Layout.WrapContent id="covers">
          <Layout.Item direction="column">
            <Layout.CenterItems>
              <Button
                margin={Spacer.space1}
                shadow
                theme={Theme.White}
                name={NameCovers.National}
                active={NameCovers.National === activeCover}
                onClick={callCovers}
              >
                <FontAwesomeIcon
                  style={{ marginRight: "6px" }}
                  icon={faNewspaper}
                />
                Diarios Nacionales
              </Button>
              <Button
                margin={Spacer.space1}
                shadow
                theme={Theme.White}
                name={NameCovers.Local}
                active={NameCovers.Local === activeCover}
                onClick={callCovers}
              >
                <FontAwesomeIcon
                  style={{ marginRight: "6px" }}
                  icon={faNewspaper}
                />
                Diarios Regionales
              </Button>
              <Button
                margin={Spacer.space1}
                shadow
                theme={Theme.White}
                name={NameCovers.Magazine}
                active={NameCovers.Magazine === activeCover}
                onClick={callCovers}
              >
                <FontAwesomeIcon
                  style={{ marginRight: "6px" }}
                  icon={faNewspaper}
                />
                Revistas
              </Button>
            </Layout.CenterItems>

            <WrapSlider>
              <Slider {...settings}>
                {currentCovers.map((props) => {
                  return <Cover {...props} token={user.token} />;
                })}
              </Slider>
            </WrapSlider>
          </Layout.Item>
        </Layout.WrapContent>
      </Layout.Wrap>
    </>
  );
};

export default SliderCovers;
