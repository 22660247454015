import React from "react";
import styled from "styled-components";
import { Spacer } from "../../../_theme/spacer";

interface SectionProps {
  title: string;
}

const SectionWrap = styled.section`
  border: 1px solid #ffc926;
  padding: 8px;
  border-radius: 5px;
  font-weight: 700;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: ${Spacer.space5};
  font-size: 18px;
  text-transform: capitalize;
`;

const Section: React.FC<SectionProps> = ({ title }: SectionProps) => {
  return (
    <SectionWrap>{title.charAt(0).toUpperCase() + title.slice(1)}</SectionWrap>
  );
};

export default Section;
