import { useEffect } from "react";

// Defined configuration piano for the experiencie authentication
const useConfigPiano = (
  aidPiano?: string,
  fLoginSuccess?: (data: any) => void
) => {
  // Handle login success.
  function handleLoginSuccess() {
    const dataUser = window.tp.pianoId.getUser();
    if (fLoginSuccess) fLoginSuccess(dataUser);
    const tokenUser = window?.tp?.pianoId?.getToken();
    postReceiverTokenUser(tokenUser);
  }

  // Handle successful registration.
  function handleRegistrationSuccess() {
    const tokenUser = window?.tp?.pianoId?.getToken();
    postReceiverTokenUser(tokenUser);
  }

  // Create a sender receiver token for a user.
  function postReceiverTokenUser(token: string) {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    let urlencoded = new URLSearchParams();
    urlencoded.append("token", token);

    fetch(`${process.env.REACT_APP_SERVICE_BASEPATH}/receiver.php?v4589`, {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
    });
  }

  // Initializes the experience.
  useEffect(() => {
    setTimeout(() => {
      var tp = window["tp"] || [];
      // tp.push(["setSandbox", true]);

      tp.push(["setUseTinypassAccounts", false]);
      tp.push(["setUsePianoIdUserProvider", true]);

      tp.push(["addHandler", "loginSuccess", handleLoginSuccess]);
      tp.push(["addHandler", "registrationSuccess", handleRegistrationSuccess]);

      tp.push([
        "init",
        function () {
          tp.experience.init();
        },
      ]);
    }, 650);
  }, [aidPiano]);
};

export default useConfigPiano;
