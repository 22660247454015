import styled from "styled-components";

const WrapCover = styled.div`
  background-size: cover;
  background-position: center center;
`

const BackgroundArrow = styled.div`
  display: flex !important;
  z-index: 1;
  padding: 15px 6px;
  border-radius: 10%;
  align-items: center;
  text-align: center;
  opacity: 0.5;
  
  ::before { display: none }

  svg {
    width: 16px !important;
    height: 32px;
  }
  
  :hover {
    opacity: 1;  
  }
`

export {
  WrapCover,
  BackgroundArrow
}
