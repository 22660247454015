export interface PackageItem {
  prodId: string;
  prodNombre: string;
  prodCodigo: string;
  prodDescripcion: string;
  prodOrden: string;
  catId: string;
  catNombre: string;
  tisId: string;
  tisPrecio: string;
}

export interface Packages {
  categorie: string;
  options: PackageItem[];
}

const getPackage = async (): Promise<Packages[]> => {
  const response = await fetch(`${process.env.REACT_APP_SERVICE_BASEPATH}/ws/pressreader/individual-packages`, {
    method: "GET",
  });

  const result = await response.json();

  const categorize = (prev: Packages[], packageItem: PackageItem) => {
    const { catNombre } = packageItem;
    const currentPackageItem = prev.find(
      ({ categorie }) => categorie === catNombre
    );

    if (currentPackageItem) {
      currentPackageItem.options = [...currentPackageItem.options, packageItem];
    } else {
      prev = [...prev, { categorie: catNombre, options: [packageItem] }];
    }
    return prev;
  };

  const toCamelCase = ({
    tis_precio,
    prod_id,
    prod_nombre,
    cat_nombre,
    catId,
    prod_codigo,
    prod_descripcion,
    prod_orden,
    tis_id,
  }: any): PackageItem => ({
    tisPrecio: tis_precio,
    prodId: prod_id,
    prodNombre: prod_nombre,
    catNombre: cat_nombre,
    catId: catId,
    prodCodigo: prod_codigo,
    prodDescripcion: prod_descripcion,
    prodOrden: prod_orden,
    tisId: tis_id,
  });

  if (response.ok) {
    return result.map(toCamelCase).reduce(categorize, []);
  }

  throw { error: "", message: result.message };
};

export const services = {
  getPackage,
};
