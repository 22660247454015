import styled from "styled-components";
import { Spacer } from "../_theme/spacer";

const WrapAppImages = styled.div`
  margin-top: ${Spacer.space3};
  display: flex;
  > img {
    margin-right: ${Spacer.space2};
  }
`;

const ClosePanelSupportBrowser = styled.div`
  position: absolute;
  top: ${Spacer.space2};
  right: ${Spacer.space2};
`;

export { WrapAppImages, ClosePanelSupportBrowser };
