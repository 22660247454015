import styled, {css} from "styled-components";
import {Colors} from "../../_theme/color";
import {Spacer} from "../../_theme/spacer";
import {FontSize} from "../Typography/Typography";

// Export enum theme.
export enum Theme {
  Default = 'DEFAULT',
  White = 'WHITE',
  Invert = "INVERT",
}

// Displays a ButtonProps.
interface ButtonProps {
  expand?: boolean;
  theme?: Theme,
  active?: boolean,
  disabled?: boolean,
  shadow?: boolean,
  bold?: boolean,
  margin?: string;
}

// Returns the css for a specific theme.
const renderTypeButton = (type: Theme, active: boolean = false) => {
  switch (type){
    case Theme.White:
      return css`
        :hover {
          color: ${ active ? Colors.BlackText : Colors.Yellow} !important;
          border-color: ${Colors.Yellow};
        }

        background-color: ${Colors.White};
        color: ${Colors.BlackText};
        border: 1px solid ${Colors.BlackText};

        ${ active ? css`
          color: ${Colors.BlackText};
          background-color: ${Colors.Yellow};
          border: ${Spacer.space0};
        ` : '' }
      `;
    case Theme.Invert:
      return css`
        background-color: ${Colors.GrayBasic};
        color: ${Colors.White} !important;
        border-color: ${Colors.GrayBasic};

        :hover {
          background-color: ${Colors.Yellow};
          color: ${Colors.Black};
          border: ${Colors.Yellow};
        }
      `
    default:
      return css`
        :hover {
          background-color: ${Colors.GrayBasic};
          color: ${Colors.White} !important;
          border-color: ${Colors.GrayBasic};
        }

        background-color: ${Colors.Yellow};
        color: ${Colors.Black};
        border: ${Colors.Yellow};
      `
  }
}

// Generates a justify button.
const Button = styled.button<ButtonProps>`
  border: 0;

  ${({theme = Theme.Default, active, disabled }) => disabled ? '' : renderTypeButton(theme, active)}

  cursor: pointer;
  font-size: ${FontSize.Middle};
  padding: ${Spacer.space1} ${Spacer.space2};
  border-radius: 5px;
  line-height: ${Spacer.space4};

  ${({ disabled }) =>  disabled ? css`
    background-color: ${Colors.GrayScale1};
    cursor: default;
    color: ${Colors.White};
  ` : ''}
  
  ${({ expand }) =>  expand ? css`
    justify-content: center;
    width: 100%;
  ` : ''}
  
  ${({bold}) => bold ? css`
    font-weight: bold;
  ` : '' }

  ${({margin}) => margin ? css`
    margin: ${margin};
  ` : '' }
  
  ${({shadow}) => shadow ? css`
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)
  ` : '' }
`;

export default Button;
