import styled, { css } from "styled-components";
import { Spacer } from "../_theme/spacer";
import { Colors } from "../_theme/color";
import { MediaQuery } from "../_theme/media-query";
import UnorderedList from "../_children/UnorderedList/UnorderedList";
import Button from "../_children/Button/Button";

const LoggedWelcome = styled.div`
  display: flex;
  align-items: center;
`;

const Wrap = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${Colors.GrayBackground};
  position: sticky;
  top: 0;
  z-index: 10;

  ${MediaQuery.mobile} {
    flex-direction: column;
    position: fixed;
    top: 0;
    z-index: 2;
    background: #fff;
    left: 0;
    right: 0;
  }
`;

const WrapContent = styled.div`
  display: flex;
  flex: 1;
  max-width: ${Spacer.max};
  justify-content: space-between;
  padding: ${Spacer.space2};

  ${MediaQuery.lessThanOrEqualToTablet} {
    display: flex;
    flex-direction: column;
  }

  ${MediaQuery.fromTabletUp} {
    margin: 0 ${Spacer.space5};
  }
`;

export interface NavContentProps {
  expanded?: boolean;
}

const NavContent = styled.div<NavContentProps>`
  transition: height 300ms ease;

  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;

  ${MediaQuery.lessThanOrEqualToTablet} {
    flex-direction: column;
    align-items: start;
    height: 0;
    overflow: hidden;
    margin-top: ${Spacer.space2};

    ${({ expanded }) =>
      expanded
        ? css`
            height: 120px;
          `
        : css`
            margin-top: 0;
          `}
  }
`;

const Menu = styled(UnorderedList)`
  margin-left: ${Spacer.space9};

  ${MediaQuery.lessThanOrEqualToTablet} {
    margin-left: 0;
    display: flex;
    flex-direction: column;
  }
`;

const Bars = styled(Button)`
  ${MediaQuery.fromDesktopUp} {
    display: none;
  }
`;

export { Wrap, WrapContent, NavContent, Menu, Bars, LoggedWelcome };
