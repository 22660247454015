import styled from "styled-components";
import {Spacer} from "../_theme/spacer";
import {Colors} from "../_theme/color";

const IndividualWrap = styled.div`
  width: 390px;
  padding: ${Spacer.space5} ${Spacer.space6} ${Spacer.space6};
  max-height: 90vh;
  overflow-x: scroll;
  box-sizing: border-box;
`;

const Divider = styled.div`
  border-top: 1px solid ${Colors.GrayScale1};
  margin: ${Spacer.space2} 0;
`

const IndividualStyle = {
  IndividualWrap,
  Divider
}

export default IndividualStyle
