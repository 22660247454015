import styled from "styled-components";
import {Spacer} from "../_theme/spacer";
import {Colors} from "../_theme/color";
import {MediaQuery} from "../_theme/media-query";
import Card, {shadowSmall} from "../_children/Card/Card";

const ImgCover = styled.img`
  max-width: 200px;
  width: 100%;
  
  ${MediaQuery.mobile} {
    max-width: 150px;  
  }
`
const WrapCover = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  margin: ${Spacer.space3} ${Spacer.space2};
  height: 360px;
`

const BackgroundArrow = styled.div`
  display: flex !important;
  background-color: ${Colors.Yellow};
  padding: 15px 6px;
  border-radius: 10%;
  align-items: center;
  text-align: center;
  top: 70%;
  
  ::before { display: none }

  svg {
    width: 16px !important;
    height: 32px;
  }
  
  :hover {
    background-color: ${Colors.YellowHover};  
  }
`

const WrapSlider = styled.div`
  width: calc(100% - 120px);
  margin: auto;
  
  ${MediaQuery.lessThanOrEqualToTablet} {
    width: calc(100vw - 80px);
  }
`
const WrapCard = styled(Card)`
  ${shadowSmall};
`

export {
  ImgCover,
  WrapCover,
  BackgroundArrow,
  WrapSlider,
  WrapCard
}
