import styled, {css} from "styled-components";
import {Colors} from "../../_theme/color";
import {Spacer} from "../../_theme/spacer";

// Creates a card interface.
interface CardProps {
  margin?: Spacer;
  type?: 'hover' | 'flat';
  padding?: string;
  flex?: boolean;
  shadow?: 'small' | 'medium';
}

export const shadowSmall = css`box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)`;
const shadowMedium = css`box-shadow: 0 2.8px 2.2px rgb(0 0 0 / 2%), 0 6.7px 5.3px rgb(0 0 0 / 3%), 0 12.5px 10px rgb(0 0 0 / 4%), 0 22.3px 17.9px rgb(0 0 0 / 4%), 0 41.8px 33.4px rgb(0 0 0 / 5%), 0 100px 80px rgb(0 0 0 / 7%);`

// Creates a new card with the given position.
const Card = styled.div<CardProps>`
  position: relative;
  background-color: ${Colors.White};
  padding: ${({padding = Spacer.space3}) => padding};
  max-width: calc(${Spacer.space9} * 10);
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
  
  ${({margin = Spacer.space0, type = 'flat', flex, shadow = 'small'}) => css`
    margin: ${margin};
    ${flex ? css` display: flex` : ''}
    ${type === 'flat' ? '' : css`
      border: 1px solid ${Colors.GrayScale1};
      :hover {
        border-color: ${Colors.Yellow};
        ${shadow === 'small' ? shadowSmall : shadowMedium}
      }
    `} 
  `
  }
`;

export default Card;
