import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {WrapCover, BackgroundArrow} from "./carousel.styled"
import Layout from "../_children/Layout/Layout.style";
import {FontSize, HeaderText} from "../_children/Typography/Typography";
import {Colors} from "../_theme/color";
import styled from "styled-components";
import Button from "../_children/Button/Button";
import slide1 from 'assets/images/carousel-slide-1.jpg';
import slide2 from 'assets/images/carousel-slide-2.jpg'
import slide3 from 'assets/images/carousel-slide-3.jpg'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleLeft} from "@fortawesome/free-solid-svg-icons/faAngleLeft";
import {faAngleRight} from "@fortawesome/free-solid-svg-icons/faAngleRight";
import React from "react";
import {MediaQuery} from "../_theme/media-query";
import {Spacer} from "../_theme/spacer";

const PrevArrow = styled(BackgroundArrow)`left: min(10%, 50px);`;
const NextArrow = styled(BackgroundArrow)`right: min(10%, 50px);`;
const ContentCarousel = styled(HeaderText)`
  
  ${MediaQuery.lessThanOrEqualToTablet} {
    max-width: 55vw;
    margin-left: 15%;
    span {
      font-size: ${FontSize.Notice};
    }

    button {
      margin-top: ${Spacer.space4};
    };
  }
  
  ${MediaQuery.mobile} {
    span {
      font-size: ${FontSize.ExtraLarge};
    }
  }
`

const settings = {
  dots: true,
  infinite: true,
  arrows: true,
  autoplay: true,
  autoplaySpeed: 3000,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  dotsClass: 'slick-dots dots-custom-carousel',
  prevArrow: <PrevArrow><FontAwesomeIcon color={Colors.White} icon={faAngleLeft} /></PrevArrow>,
  nextArrow: <NextArrow><FontAwesomeIcon color={Colors.White} icon={faAngleRight} /></NextArrow>,
};

const ContentText = styled.div`
  max-width: 450px;
  padding: 0 2vw;
  display: flex;
  align-items: center;
  ${MediaQuery.lessThanOrEqualToTablet} {
    padding: 0 10vw;  
  }
`

const ContentSlider = () => (
  <Layout.Wrap style={{height: '100%'}}>
    <Layout.WrapContent>
      <ContentText>
        <ContentCarousel>
          <HeaderText color={Colors.White} type={FontSize.Notice}><b>Los mejores</b> diarios y revistas a donde vayas</HeaderText>
          <Button bold={true} onClick={() => window.location.href = '/#subscriptions'}>
            Suscríbete ahora
          </Button>
        </ContentCarousel>
      </ContentText>
    </Layout.WrapContent>
  </Layout.Wrap>
)

const Carousel = () => {

  return <Slider {...settings}>
    <div>
      <WrapCover style={{ backgroundImage: `url("${slide1}")`, height: '65vh'}}>
        <ContentSlider />
      </WrapCover>
    </div>
    <div>
      <WrapCover style={{ backgroundImage: `url("${slide2}")`, height: '65vh'}}>
        <ContentSlider />
      </WrapCover>
    </div>
    <div>
      <WrapCover style={{ backgroundImage: `url("${slide3}")`, height: '65vh'}}>
        <ContentSlider />
      </WrapCover>
    </div>
  </Slider>
}

export default Carousel;

