import styled, {css} from "styled-components";
import {Colors} from "../../_theme/color";

type Direction = 'vertical' | 'horizontal';

interface UnorderedListProps {
  color?: Colors.Black | Colors.GrayScale1;
  listStyle?: 'none' | 'disc';
  direction?: Direction;
  native?: boolean;
}

const UnorderedList = styled.ul<UnorderedListProps>`
  ${({native = false}) => native ? `` : css`
    margin: 0;
    padding: 0;
  `};
  color: ${({ color = Colors.Black }) => color};
  list-style: ${({ listStyle = 'none' }) => listStyle };
  ${({direction = 'vertical'}) => (direction === 'vertical' ? css`
    display: block;
  ` : css`
    display: flex;
    > li {
      margin: 10px;
    }
  `)};
`;

export default UnorderedList;
