import React, { ChangeEventHandler, ReactNode } from "react";
import styled from "styled-components";
import {Spacer} from "../../../_theme/spacer";

interface CheckBoxProps {
  id: string;
  label: ReactNode;
  className?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
}

const CheckBoxWrap = styled.div`
  display: flex;
  padding: ${Spacer.space1};

  input {
    min-width: 13px;
    min-height: 13px;
    height: 13px;
    width: 13px;
    max-width: 13px;
    max-height: 13px;
  }

  label {
    margin-left: ${Spacer.space5};
  }
`

const CheckBox: React.FC<CheckBoxProps> = ({
  id,
  label,
  onChange,
}: CheckBoxProps) => {
  return (
    <CheckBoxWrap className="checkbox-component">
      <input type="checkbox" id={id} name={id} onChange={onChange} />
      <label htmlFor={id}>{label}</label>
    </CheckBoxWrap>
  );
};

export default CheckBox;
