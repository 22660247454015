import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {faArrowUp} from "@fortawesome/free-solid-svg-icons/faArrowUp";
import styled from "styled-components";
import {Spacer} from "../../_theme/spacer";
import Button, {Theme} from "../Button/Button";
import {Colors} from "../../_theme/color";

const ScrollUpWrap = styled.div`
  position: fixed;
  bottom: ${Spacer.space5};
  right: ${Spacer.space5};
`

const ScrollUp = () => {
  return (
    <ScrollUpWrap>
      <Button theme={Theme.Invert} onClick={() => window.location.href = '#home'}>
        <FontAwesomeIcon icon={faArrowUp} size="2x" color={Colors.White} />
      </Button>
    </ScrollUpWrap>
  )
}

export default ScrollUp;
