import React from "react";

// Page of data treatment (spanish: tratamiento de datos).
function DataTreatment() {
  return (
    <div className="data-treatment">
      <h1>TRATAMIENTOS DE DATOS</h1>
      <hr></hr>

      <h3>CONSENTIMIENTO PARA EL TRATAMIENTO DE DATOS PERSONALES</h3>
      <p>
        EL COMERCIO valora a sus usuarios y está comprometida con la protección
        de su privacidad. En ese sentido, hemos desarrollado el presente
        consentimiento para el tratamiento de sus datos personales para las
        finalidades y de la forma en que se detalla a continuación. Usted tiene
        la libertad de conceder o denegar el presente consentimiento.
      </p>

      <h3>I. ASPECTOS GENERALES</h3>

      <p>
        1. EL COMERCIO se encuentra constituida bajo la razón social EMPRESA
        EDITORA EL COMERCIO S.A., identificada con RUC 20143229816 y domiciliada
        en Jr. Jorge Salazar Araoz Nro. 171, Urbanización Santa Catalina,
        Distrito de La Victoria, Provincia y Departamento de Lima.
      </p>

      <p>
        2. Al tratamiento que EL COMERCIO realizará de tus datos personales en
        base a la presente cláusula, le resultará aplicable nuestra Política de
        Privacidad, la cual se encuentra en
        https://elcomercio.pe/politicas-privacidad/.
        <br />
        <br />
        Usted acepta haber sido debidamente informado por EL COMERCIO de la
        Política de Privacidad.
      </p>

      <p>
        3. Para efectos del presente consentimiento nos autorizas a enriquecer
        nuestra base de datos con información personal tuya a la que podamos
        acceder a través de fuentes públicas o de terceros autorizados a
        compartir tu información personal. En ese sentido, toda la información
        adicional que recopilamos puede combinarse con la información
        proporcionada por usted para las finalidades autorizadas en el presente
        consentimiento.
      </p>

      <p>
        4. Para efectos del presente consentimiento, toda referencia a “nos”,
        “nosotros” o “nuestra”, se refiere a EL COMERCIO; y cuando se refiera a
        “usted”, “el Usuario”, “los Usuarios” o alguna variación de éstos, se
        entenderá a todos aquellos individuos distintos a EL COMERCIO
        registrados como usuarios de este sitio web y sus aplicativos (en
        adelante y de manera conjunta, el “Sitio Web”).
      </p>

      <h3>II. AUTORIZACIÓN PARA USOS ADICIONALES</h3>
      <p>
        5. Además de los tratamientos que resultan necesarios por la relación
        que tiene con EL COMERCIO, nos autoriza a que, de manera directa o a
        través de nuestros encargados de tratamiento listados en nuestra
        Política de Privacidad, podamos hacer lo siguiente:
      </p>

      <ul>
        <li>
          Realizar estudios internos sobre sus intereses y comportamientos a fin
          de ofrecerle un mejor servicio de acuerdo con sus necesidades y usos
          específicos, así como para enviarle contenido personalizado sobre la
          base de sus intereses.
        </li>
        <li>
          Construir su perfil sobre la base de la información que nos entrega a
          través de nuestro Sitio Web, a fin de enviarle todo tipo de noticias,
          así como promociones de productos y/o servicios relacionados a, entre
          otros, los siguientes mercados: (i) automotriz; (ii) editorial; (iii)
          periodístico; (iv) de apuestas; (v) tecnológico; (vi) inmobiliario; y,
          (vii) financiero.
        </li>
      </ul>

      <p>
        6. Asimismo, autorizas a EL COMERCIO a transferir tu información a las
        siguientes empresas (“Terceros”) que forman parte del Grupo El Comercio,
        para los fines descritos a continuación:
      </p>

      <ul>
        <li>
          Comercio Digital Integral S.A.C., domiciliada en Perú, para que ésta
          le envíe publicidad y promociones comerciales de los productos y/
          servicios que ofrezca, a través de su plataforma web: www.lumingo.com.
        </li>
        <li>
          Amauta Impresiones Comerciales S.A.C., domiciliada en Perú, para que
          ésta les envíe todo tipo de publicidad y promociones comerciales, de
          los productos y/o servicios que ofrezcan.
        </li>
        <li>
          WWW.PERU.COM S.A. domiciliada en Perú, para que ésta les envíe: (i)
          información periodística nacional e internacional; y, (ii) publicidad
          y promociones comerciales de los productos y/ servicios que ofrezca.
        </li>
        <li>
          Orbis Ventures S.A.C., domiciliada en Perú, para que ésta les envíe
          todo tipo de publicidad y promociones comerciales, de los productos
          y/o servicios que ofrezcan.
        </li>
        <li>
          CDI Logística S.A.C., domiciliada en Perú, para que ésta les envíe
          todo tipo de publicidad y promociones comerciales, de los productos
          y/o servicios que ofrezcan.
        </li>
        <li>
          Prensmart S.A.C., domiciliada en Perú, para que ésta les envíe: (i)
          información periodística nacional e internacional; y, (ii) publicidad
          y promociones comerciales de los productos y/ servicios que ofrezca.
        </li>
        <li>
          Neoauto S.A.C., domiciliada en Perú, para que ésta les envíe todo tipo
          de publicidad y promociones comerciales, de los productos y/o
          servicios que ofrezcan.
        </li>
      </ul>

      <p>
        7. El envío de comunicaciones o mensajes con las ofertas y/o
        publicidades podrá ser remitida a través de medios escritos (por
        ejemplo, comunicaciones físicas), verbales (por ejemplo, llamadas
        telefónicas) o electrónicos/informáticos (por ejemplo, SMS, push
        notifications o correo electrónico).
      </p>

      <p>
        8. EL COMERCIO y los Terceros podrán conservar y tratar tu información
        en los términos establecidos en esta sección, mientras se mantenga
        nuestra relación contractual y luego de diez (10) años de finalizada. En
        el caso de EL COMERCIO, tu información será almacenada en las siguientes
        bases de datos denominadas “Suscriptores y Ex Suscriptores” y (Diario El
        Comercio), registradas con Código 08006 y 8009, respectivamente, de
        nuestra titularidad, las cuales se encuentran ubicadas en el Jr. Jorge
        Salazar Araoz Nro. 171, Urbanización Santa Catalina, Distrito de La
        Victoria, Provincia y Departamento de Lima.
      </p>

      <p>
        9. Para las finalidades objeto del presente consentimiento, EL COMERCIO
        tratará la información que usted ha brindado al registrarse como
        usuario.
      </p>

      <p>
        10. Al tratamiento de tu información por parte de EL COMERCIO y los
        Terceros le resulta aplicables las condiciones mencionadas en nuestra
        Política de Privacidad. Puedes tener más información sobre ésta en el
        enlace del numeral 2 anterior.
      </p>

      <p>
        11. Debe tener en cuenta que la aceptación o denegatoria de esta
        autorización para usos adicionales (ajenos a la ejecución de nuestra
        relación) no condiciona la prestación del servicio que estás contratando
        con EL COMERCIO.
      </p>

      <h3>EMPLEO DE COOKIES</h3>

      <p>
        12. EL COMERCIO utiliza cookies para mejorar la experiencia de los
        Usuarios, informando siempre acerca del envío de cookies a las
        computadoras o dispositivos móviles cada vez que visiten el Sitio Web.
      </p>

      <p>
        13. Por medio de cookies EL COMERCIO podrá recabar la siguiente
        información: comportamiento al navegar en el Sitio Web; información
        sobre las actividades realizadas por el Usuario cuando accede,
        visualiza, crea, comenta y comparte contenido, entre otros; la URL de la
        que proviene el Usuario (incluyendo las externas al Sitio Web); las URL
        más visitadas por el Usuario (incluyendo las externas al Sitio Web);
        direcciones IP; información sobre la ubicación del Usuario; navegador
        que utiliza el Usuario; información sobre aspectos operativos del Sitio
        Web, como estadísticas de navegación, tráfico, entre otros.
      </p>

      <p>
        14. Usted autoriza a EL COMERCIO a tratar la información obtenida por
        medio de cookies para las finalidades y en los términos establecidos en
        el Consentimiento para el Tratamiento de Datos Personales anterior,
        siendo éste aplicable en todos sus términos. Debe tener en cuenta que la
        aceptación o denegatoria de esta autorización para usos adicionales
        (ajenos a la ejecución de nuestra relación) no condiciona la prestación
        del servicio que estás contratando con EL COMERCIO.
      </p>

      <p>
        15. Para mayor detalle sobre nuestra Política de Cookies, puede acceder
        al siguiente enlace: https://elcomercio.pe/politica-de-cookies/
      </p>
    </div>
  );
}

export default DataTreatment;
