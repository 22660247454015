import {
  FontSize,
  HeaderText,
  LegalText,
} from "../_children/Typography/Typography";
import UnorderedList from "../_children/UnorderedList/UnorderedList";
import Link, { TypeLink } from "../_children/Link/Link.style";
import { Colors } from "../_theme/color";
import { WrapAppImages, ClosePanelSupportBrowser } from "./footer.styled";
import { siteFooter } from "./site-footer.enum";
import { Spacer } from "../_theme/spacer";
import Layout from "../_children/Layout/Layout.style";
import styled from "styled-components";
import logoWhite from "assets/images/logo-white.png";
import appStoreApple from "assets/images/appstore-apple.png";
import appStoreGoogle from "assets/images/appstore-google.png";
import downloadApp from "assets/images/download-app-mockup.png";
import browsers from "assets/images/browsers.png";
import bookmark from "assets/svg/bookmark.svg";
import translate from "assets/svg/translate.svg";
import volume from "assets/svg/volume.svg";
import club2020 from "assets/svg/club2020-logo.svg";
import BenefitCard from "./_children/BenefitCard";
import { faPhone } from "@fortawesome/free-solid-svg-icons/faPhone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import React, { useState } from "react";

const FloatBar = styled(Layout.Wrap)`
  background-color: rgba(255, 202, 38, 0.85);
`;

const Image = styled.img`
  margin: ${Spacer.space5} ${Spacer.space2};
`;

const Footer = () => {
  const [showBrowserSupport, setShowBrowserSupport] = useState(true);

  return (
    <>
      <Layout.Wrap color={Colors.GrayScale0}>
        <Layout.WrapContent>
          <Layout.Item
            direction="column"
            textAlign="center"
            padding={Spacer.space3}
          >
            <HeaderText element="p" bold type={FontSize.BigLarge}>
              Conoce nuestros beneficios
            </HeaderText>
            <HeaderText type={FontSize.Large}>
              Nuestras ediciones digitales cuentan con nuevas funciones
              interactivas a las cuales puedes acceder desde diferentes
              dispositivos para una mejor experiencia de información.
            </HeaderText>
          </Layout.Item>
        </Layout.WrapContent>
      </Layout.Wrap>

      

      <Layout.Wrap color={Colors.GrayScale0}>
        <Layout.WrapContent>
          <Layout.Item>
            <BenefitCard
              title="Audio"
              description="Te narramos las noticias de los diarios o revistas de tu preferencia."
              icon={volume}
            />
          </Layout.Item>
          <Layout.Item>
            <BenefitCard
              title="Traducir"
              description="Traduce nuestros contenidos hasta en 20 idiomas."
              icon={translate}
            />
          </Layout.Item>
          <Layout.Item>
            <BenefitCard
              title="Marcador"
              description="Guarda los artículos de tu interés y crea colecciones de manera ilimitada."
              icon={bookmark}
            />
          </Layout.Item>
        </Layout.WrapContent>
      </Layout.Wrap>

      <Layout.Wrap color={Colors.GrayScale0} >
        <Layout.WrapContent>
          <Layout.Item
            direction="column"
            textAlign="center"
            padding={Spacer.space3}
            backgroundColor="white"
          >
            <HeaderText element="p" bold type={FontSize.BigLarge}>
          <img src={club2020} alt="" height={50} />  Club de Suscriptores
            </HeaderText>
            <HeaderText type={FontSize.Large}  >
              Con tu suscripción de <b>Paquete Todo incluido</b> o <b>Todo Diarios</b> accede
              a más <br /> de 300 establecimientos con hasta 70% de descuento.
            </HeaderText>
            <div style={{
              height: '80px'
            }}></div>
          </Layout.Item>
        </Layout.WrapContent>
      </Layout.Wrap>

      <Layout.Wrap color={Colors.GrayBackground}>
        <Layout.WrapContent>
          <Layout.Item>
            <img src={downloadApp} width="290" alt="logo de store mobile" />
          </Layout.Item>
          <Layout.Item>
            <div>
              <div>
                <HeaderText bold>Disponible para:</HeaderText>
              </div>
              <UnorderedList native listStyle="disc">
                <li>Mac</li>
                <li>Tablet</li>
                <li>Smartphone</li>
              </UnorderedList>
            </div>
          </Layout.Item>
          <Layout.Item>
            <div>
              <div>
                <HeaderText bold>Descarga la aplicación desde:</HeaderText>
              </div>
              <WrapAppImages>
                <img src={appStoreApple} height="35" alt="" />
                <img src={appStoreGoogle} height="35" alt="" />
              </WrapAppImages>
            </div>
          </Layout.Item>
        </Layout.WrapContent>
      </Layout.Wrap>
      {showBrowserSupport && (
        <Layout.Sticky bottom="0px">
          <FloatBar>
            <Layout.WrapContent>
              <Layout.Item padding={Spacer.space3}>
                <Layout.Center>
                  <HeaderText bold type={FontSize.Middle}>
                    Hola! Para garantizar una mejor experiencia en nuestro
                    portal web te recomendamos utilizar Chrome, Edge o Firefox.
                  </HeaderText>
                  <img src={browsers} alt="Navegadores" width={100} />
                </Layout.Center>
              </Layout.Item>
              <ClosePanelSupportBrowser>
                <a
                  href="javascript:void(0)"
                  onClick={() => setShowBrowserSupport(false)}
                >
                  <FontAwesomeIcon
                    size="lg"
                    color={Colors.BlackText}
                    icon={faTimes}
                  />
                </a>
              </ClosePanelSupportBrowser>
            </Layout.WrapContent>
          </FloatBar>
        </Layout.Sticky>
      )}
      <Layout.Wrap color={Colors.Black}>
        <Layout.WrapContent>
          <Layout.Item>
            <div>
              <img
                alt="Logo PeruQuiosco"
                width={125}
                height={48}
                src={logoWhite}
              />
              <LegalText element="p" bold={true}>
                Empresa Editora El Comercio Jr. Miro Quesada 300, Lima - Peru
              </LegalText>
            </div>
          </Layout.Item>
          <Layout.Item>
            <UnorderedList listStyle="disc" color={Colors.GrayScale1}>
              {siteFooter.map(({ href, text }) => (
                <li key={text}>
                  <Link
                    href={href}
                    target="_blank"
                    bold={true}
                    fontSize={FontSize.Small}
                    typeLink={TypeLink.Gray}
                  >
                    {text}
                  </Link>
                </li>
              ))}
            </UnorderedList>
          </Layout.Item>
          <Layout.Item>
            <div>
              <LegalText bold color={Colors.Yellow}>
                VENTAS & SOPORTE
              </LegalText>
              <p>
                <FontAwesomeIcon
                  style={{ marginRight: "6px" }}
                  icon={faPhone}
                  color="#FFF"
                />
                <Link typeLink={TypeLink.Gray} href="tel:01311 5100">
                  311 5100
                </Link>
              </p>
            </div>
          </Layout.Item>
          <Layout.Item />
        </Layout.WrapContent>
      </Layout.Wrap>
    </>
  );
};

export default Footer;
