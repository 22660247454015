// https://peruquiosco.pe/ws/pressreader/packages/
import Layout from "../_children/Layout/Layout.style";
import { WrapCards, WrapContent } from "./Content.style";
import { useEffect, useState } from "react";
import { FontSize, HeaderText } from "../_children/Typography/Typography";
import { Colors } from "../_theme/color";
import PackageCard, {
  Package,
  PackageType,
} from "./_children/PackageCard/PackageCard";
import Modal from "../_children/Modal/Modal";
import Individuals from "../Individuals/Individual";

// Function for format params of packages
const mapper = (result: object[]): Package[] => {
  return result.map(
    ({
      prod_nombre,
      tis_precio,
      desc_paquete,
      img_paquete,
      prod_paquete,
      url_paquete,
      action,
    }: any): Package => ({
      action,
      title: prod_nombre,
      description: desc_paquete,
      price: tis_precio,
      imageUrl:
        img_paquete ??
        "https://r.prcdn.co/res/es-mx/g23236/t359636856/2/images/subscription-thumb-1.jpg",
      items: prod_paquete.map((itemPack: any) => ({
        name: itemPack.prod_nombre,
        price: itemPack.tis_precio,
      })),
      urlPackage: url_paquete,
    })
  );
};

const Content = () => {
  // Sets the use state of all packages.
  const [packages, setPackages] = useState<Package[]>([]);
  // Sets the show modal state.
  const [showModal, setShowModal] = useState(false);

  // Fetch a list of packages subscriptions, excluded code previstas.
  useEffect(() => {
    const getPackage = async (): Promise<void> => {
      // REVERT TO PROD -  `${process.env.REACT_APP_SERVICE_BASEPATH}/ws/pressreader/packages`
      // TEST - `https://pre.peruquiosco.pe/ws/pressreader/packages`

      const response = await fetch(
        `${process.env.REACT_APP_SERVICE_BASEPATH}/ws/pressreader/packages`
      );
      if (response.ok) {
        let result = await response.json();
        result = result.filter((item: any) => item.prod_codigo !== "previstas");
        result = result.map((item: any) => {
          const packItems = item.prod_paquete.filter(
            (packItem: any) => packItem.prod_codigo !== "peru21" && packItem.prod_codigo !== "dperu21norte" && packItem.prod_codigo !== "dperu21sur"
          );
          item.prod_paquete = packItems;
          return item;
        });
        result = orderPackages(result, 1, 2);
        setPackages(mapper(result));
      }
    };
    getPackage();
  }, []);

  // Returns a human - readable description of a text header package.
  const checkTextHeaderPackage = (index: number) => {
    switch (index) {
      case 0:
        return "Diarios";
      case 1:
        return "Diarios";
      case 2:
        return "Revistas";
      case 3:
        return "Diarios y Revistas";
      default:
        return "Diarios";
    }
  };

  // Orders the packages in an array.
  function orderPackages(array: any[], indexA: number, indexB: number) {
    var tmp = array[indexA];
    array[indexA] = array[indexB];
    array[indexB] = tmp;
    return array;
  }

  return (
    <WrapContent>
      <Layout.WrapContent>
        <div>
          <Layout.Center>
            <HeaderText
              element="p"
              color={Colors.BlackText}
              type={FontSize.BigLarge}
              bold
            >
              Suscripciones
            </HeaderText>
          </Layout.Center>
          <WrapCards>
            {packages.map((props, index) => (
              <PackageCard
                key={index}
                {...props}
                textHeader={checkTextHeaderPackage(index)}
                onSubscribe={() => {
                  if (window.tp.pianoId.getUser() != null) {
                    if (props.action === PackageType.IndividualPackages) {
                      setShowModal(true);
                      return;
                    }
                    window.location.href = props.urlPackage;
                  } else {
                    window.tp.pianoId.show();
                  }
                }}
              />
            ))}
          </WrapCards>
        </div>
      </Layout.WrapContent>
      <Modal show={showModal} setShow={setShowModal}>
        <Individuals setShow={setShowModal} />
      </Modal>
    </WrapContent>
  );
};

export default Content;
