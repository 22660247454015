import React, {FC, ReactNode, useEffect, useState} from "react";
import styled from "styled-components";

interface ModalProps {
  children: ReactNode,
  show: boolean,
  setShow: React.Dispatch<React.SetStateAction<boolean>>
}

const WrapModal = styled.div`
  background-color: rgba(0, 0, 0, .8);
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 20;
  justify-content: center;
  display: flex;
  align-items: center;
`;

const BackgroundModal = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const ContentModal = styled.div`
  position: relative;
  margin: 0 auto;
  background: #FFF;
  border-radius: 8px;
  max-height: 90vh;
`

const Modal: FC<ModalProps> = ({children, show, setShow}: ModalProps) => {

  return (
    show ? (
      <WrapModal>
        <BackgroundModal onClick={() => setShow(false)} />
        <ContentModal>
          {children}
        </ContentModal>
      </WrapModal>
    ) : null
  )
}

export default Modal;
