import {Colors} from "../../_theme/color";
import styled, {css} from "styled-components";
import {Spacer} from "../../_theme/spacer";
import {MediaQuery} from "../../_theme/media-query";

interface WrapProps {
  color?: Colors
}


interface WrapItemProps {
  direction?: 'column' | 'row',
  textAlign?: string,
  padding?: string,
  backgroundColor?: string,
}

const Wrap = styled.div<WrapProps>`
  ${({ color}) => color ? css`background-color: ${color}` : '' };
  justify-content: center;
  display: flex;
`;

const WrapContent = styled.div`
  display: flex;
  max-width: ${Spacer.max};
  width: 100%;
  padding-top: 60px;
  flex: 1;
  
  ${MediaQuery.mobile} {
    flex-direction: column;
    margin: auto ${Spacer.space2};
    width: auto; 
  }
  
  ${MediaQuery.fromTabletUp} {
    margin: auto ${Spacer.space5};
    padding-top: 30px;
  }
`;

const Item = styled.div<WrapItemProps>`
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: ${({padding = `${Spacer.space8} 0`}) => padding} ;
  flex-direction: ${({direction = 'row'}) => direction};
  background-color: ${({backgroundColor = 'initial'}) => backgroundColor};
  ${({textAlign}) => textAlign ? css`text-align: ${textAlign}`: ''};
  
  ${MediaQuery.mobile} {
    justify-content: start;
    padding: ${Spacer.space4} 0;
  }
`;

interface CenterProps {
  padding?: string;
}

const Center = styled.div<CenterProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  ${({padding = 0}) => `
    padding: ${padding}
  `}
`

const CenterItems = styled.div`
  display: flex;
  justify-content: center;
  
  ${MediaQuery.mobile} {
    flex-wrap: wrap;
  }
`

interface StickProps {
  bottom?: string;
}

const Sticky = styled.div<StickProps>`
  position: sticky;
  ${({ bottom }) => bottom ? css`bottom: ${bottom};` : ''}
`

const Layout = {
  Wrap,
  WrapContent,
  Item,
  Center,
  CenterItems,
  Sticky
}

export default Layout;
