import dayjs, { Dayjs } from "dayjs";
import holidays from "date-holidays";
import { extraHolidays } from "../../../../constants/extraHolidays";
// REVERT TO PROD - `https://web.peruquiosco.pe`

// Defined const route base pressreader sandbox or prod.
const ROUTE_BASE_PRESSREADER =
  process.env.REACT_APP_ENV === `PROD`
    ? `https://visor.peruquiosco.pe`
    : `https://peruquioscopruebas.pressreader.com`;

// Checks if a given date is a Saturday.
const isSaturday = (date: Dayjs) => dayjs(date).day() === 6;
// Checks if a given date is a sunday.
const isSunday = (date: Dayjs) => dayjs(date).day() === 0;
// Checks if a given date is a monday.
const isMonday = (date: Dayjs) => dayjs(date).day() === 1;
// Checks if a date is a weekend.
const isWeekend = (date: Dayjs) => isSaturday(date) || isSunday(date);

// Defined date.
const today: Dayjs = dayjs();
// Format YYYYMMDD to today.
const formattedToday: string = today.format("YYYYMMDD");
// Returns a list of all holidays in the current year.
let listHolidays: any = new holidays("PE").getHolidays(today.year());

// Verify exist new holidays and returns the list of holidays and extra holidays.
if (extraHolidays.length > 0)
  listHolidays = [...listHolidays, ...extraHolidays];

// Returns a formatted date for a printed version of friday
function showPrintedVersionFriday(): string {
  if (isSaturday(today))
    return dayjs(today).subtract(1, "day").format("YYYYMMDD");
  if (isSunday(today))
    return dayjs(today).subtract(2, "day").format("YYYYMMDD");
  return formattedToday;
}

// Checks if a date is a valid holiday
function isHoliday(date: Dayjs) {
  let verifyHoliday = false;
  listHolidays.forEach((dateHoliday: any) => {
    if (date.isSame(dateHoliday.date, "day")) verifyHoliday = true;
  });
  return verifyHoliday;
}

// Returns a human - readable DATE describing the printed version of the holiday.
function showPrintedVersionBeforeToHoliday(date: Dayjs): string {
  if (isMonday(date)) {
    const substract3Day = dayjs(date).subtract(3, "day");
    if (isHoliday(substract3Day))
      return showPrintedVersionBeforeToHoliday(substract3Day);
    return substract3Day.format("YYYYMMDD");
  }

  const substract1Day = dayjs(date).subtract(1, "day");
  if (isHoliday(substract1Day))
    return showPrintedVersionBeforeToHoliday(substract1Day);
  return substract1Day.format("YYYYMMDD");
}

// Export list covers news and magazine.
export const coversList = [
  [
    {
      link: `${ROUTE_BASE_PRESSREADER}/eaaj`,
      title: `El Comercio`,
      img: `https://i.prcdn.co/img?cid=eaaj&v=0&ver=0&width=200&date=${formattedToday}`,
    },
    {
      link: `${ROUTE_BASE_PRESSREADER}/eag6`,
      title: `Gestión`,
      img: `https://i.prcdn.co/img?cid=eag6&v=0&ver=0&width=200&date=${
        isWeekend(today)
          ? showPrintedVersionFriday()
          : isHoliday(today)
          ? showPrintedVersionBeforeToHoliday(today)
          : formattedToday
      }`,
    },
    {
      link: `${ROUTE_BASE_PRESSREADER}/eag2`,
      title: `Depor`,
      img: `https://i.prcdn.co/img?cid=eag2&v=0&ver=0&width=200&date=${formattedToday}`,
    },
    {
      link: `${ROUTE_BASE_PRESSREADER}/eag8`,
      title: `Trome`,
      img: `https://i.prcdn.co/img?cid=eag8&v=0&ver=0&width=200&date=${formattedToday}`,
    },
    {
      link: `${ROUTE_BASE_PRESSREADER}/eagb`,
      title: `Correo`,
      img: `https://i.prcdn.co/img?cid=eagb&v=0&ver=0&width=200&date=${formattedToday}`,
    },
    {
      link: `${ROUTE_BASE_PRESSREADER}/eags`,
      title: `Diario Ojo`,
      img: `https://i.prcdn.co/img?cid=eags&v=0&ver=0&width=200&date=${formattedToday}`,
    },
    {
      link: `${ROUTE_BASE_PRESSREADER}/eag1`,
      title: `Perú21`,
      // img: `https://i.prcdn.co/img?cid=eag1&v=0&ver=0&width=200&date=${formattedToday}`,
      img: `https://i.prcdn.co/img?cid=eag1&v=0&ver=0&width=200&date=20230131`,
    },
  ],
  [
    {
      link: `${ROUTE_BASE_PRESSREADER}/eagd`,
      title: `Correo Arequipa`,
      img: `https://i.prcdn.co/img?cid=eagd&v=0&ver=0&width=200&date=${formattedToday}`,
    },
    // {
    //   link: `${ROUTE_BASE_PRESSREADER}/eagh`,
    //   title: `Correo Ayacucho`,
    //   img: `https://i.prcdn.co/img?cid=eagh&v=0&ver=0&width=200&date=${formattedToday}`,
    // },
    // {
    //   link: `${ROUTE_BASE_PRESSREADER}/eagn`,
    //   title: `Correo Chimbote`,
    //   img: `https://i.prcdn.co/img?cid=eagn&v=0&ver=0&width=200&date=${formattedToday}`,
    // },
    // {
    //   link: `${ROUTE_BASE_PRESSREADER}/eagk`,
    //   title: `Correo Huancavelica`,
    //   img: `https://i.prcdn.co/img?cid=eagk&v=0&ver=0&width=200&date=${formattedToday}`,
    // },
    {
      link: `${ROUTE_BASE_PRESSREADER}/eagg`,
      title: `Correo Huancayo`,
      img: `https://i.prcdn.co/img?cid=eagg&v=0&ver=0&width=200&date=${formattedToday}`,
    },
    {
      link: `${ROUTE_BASE_PRESSREADER}/eagj`,
      title: `Correo Huanuco`,
      img: `https://i.prcdn.co/img?cid=eagj&v=0&ver=0&width=200&date=${formattedToday}`,
    },
    {
      link: `${ROUTE_BASE_PRESSREADER}/eagc`,
      title: `Correo Ica`,
      img: `https://i.prcdn.co/img?cid=eagc&v=0&ver=0&width=200&date=${formattedToday}`,
    },
    {
      link: `${ROUTE_BASE_PRESSREADER}/eagl`,
      title: `Correo La Libertad`,
      img: `https://i.prcdn.co/img?cid=eagl&v=0&ver=0&width=200&date=${formattedToday}`,
    },
    {
      link: `${ROUTE_BASE_PRESSREADER}/eagm`,
      title: `Correo Lambayeque`,
      img: `https://i.prcdn.co/img?cid=eagm&v=0&ver=0&width=200&date=${formattedToday}`,
    },
    {
      link: `${ROUTE_BASE_PRESSREADER}/eagp`,
      title: `Correo Piura`,
      img: `https://i.prcdn.co/img?cid=eagp&v=0&ver=0&width=200&date=${formattedToday}`,
    },
    // {
    //   link: `${ROUTE_BASE_PRESSREADER}/eage`,
    //   title: `Correo Puno`,
    //   img: `https://i.prcdn.co/img?cid=eage&v=0&ver=0&width=200&date=${formattedToday}`,
    // },
    {
      link: `${ROUTE_BASE_PRESSREADER}/eagf`,
      title: `Correo Tacna`,
      img: `https://i.prcdn.co/img?cid=eagf&v=0&ver=0&width=200&date=${formattedToday}`,
    },
    // {
    //   link: `${ROUTE_BASE_PRESSREADER}/eagq`,
    //   title: `Correo Tumbes`,
    //   img: `https://i.prcdn.co/img?cid=eagq&v=0&ver=0&width=200&date=${formattedToday}`,
    // },
    {
      link: `${ROUTE_BASE_PRESSREADER}/eag5`,
      title: `Depor Centro`,
      img: `https://i.prcdn.co/img?cid=eag5&v=0&ver=0&width=200&date=${formattedToday}`,
    },
    {
      link: `${ROUTE_BASE_PRESSREADER}/eag3`,
      title: `Depor Norte`,
      img: `https://i.prcdn.co/img?cid=eag3&v=0&ver=0&width=200&date=${formattedToday}`,
    },
    {
      link: `${ROUTE_BASE_PRESSREADER}/eag4`,
      title: `Depor Sur`,
      img: `https://i.prcdn.co/img?cid=eag4&v=0&ver=0&width=200&date=${formattedToday}`,
    },
    {
      link: `${ROUTE_BASE_PRESSREADER}/eagr`,
      title: `Diario Ojo Centro`,
      img: `https://i.prcdn.co/img?cid=eagr&v=0&ver=0&width=200&date=${formattedToday}`,
    },
    {
      link: `${ROUTE_BASE_PRESSREADER}/eagt`,
      title: `Diario Ojo Norte`,
      img: `https://i.prcdn.co/img?cid=eagt&v=0&ver=0&width=200&date=${formattedToday}`,
    },
    {
      link: `${ROUTE_BASE_PRESSREADER}/eagu`,
      title: `Diario Ojo Sur`,
      img: `https://i.prcdn.co/img?cid=eagu&v=0&ver=0&width=200&date=${formattedToday}`,
    },
    {
      link: `${ROUTE_BASE_PRESSREADER}/eag7`,
      title: `Trome Huancayo`,
      img: `https://i.prcdn.co/img?cid=eag7&v=0&ver=0&width=200&date=${formattedToday}`,
    },
    {
      link: `${ROUTE_BASE_PRESSREADER}/eag9`,
      title: `Trome Norte`,
      img: `https://i.prcdn.co/img?cid=eag9&v=0&ver=0&width=200&date=${formattedToday}`,
    },
    {
      link: `${ROUTE_BASE_PRESSREADER}/eaga`,
      title: `Trome Sur`,
      img: `https://i.prcdn.co/img?cid=eaga&v=0&ver=0&width=200&date=${formattedToday}`,
    },
  ],
  [
    {
      link: `${ROUTE_BASE_PRESSREADER}/eahk`,
      title: `Dossier de Arquitectura`,
      img: `https://i.prcdn.co/img?cid=eahk&v=0&ver=0&width=200`,
    },
    {
      link: `${ROUTE_BASE_PRESSREADER}/eaht`,
      title: `Revista Arq`,
      img: `https://i.prcdn.co/img?cid=eaht&v=0&ver=0&width=200`,
    },
    {
      link: `${ROUTE_BASE_PRESSREADER}/eahj`,
      title: `Energiminas`,
      img: `https://i.prcdn.co/img?cid=eahj&v=0&ver=0&width=200`,
    },
    {
      link: `${ROUTE_BASE_PRESSREADER}/EAHR`,
      title: `Revista BÖRBON`,
      img: `https://i.prcdn.co/img?cid=eahr&v=0&ver=0&width=200`,
    },
    {
      link: `${ROUTE_BASE_PRESSREADER}/eahm`,
      title: `Revista Constructivo`,
      img: `https://i.prcdn.co/img?cid=eahm&v=0&ver=0&width=200`,
    },
    {
      link: `${ROUTE_BASE_PRESSREADER}/eahl`,
      title: `Revista G`,
      img: `https://i.prcdn.co/img?cid=eahl&v=0&ver=0&width=200`,
    },
    {
      link: `${ROUTE_BASE_PRESSREADER}/EAHQ`,
      title: `Revista LUHHO`,
      img: `https://i.prcdn.co/img?cid=eahq&v=0&ver=0&width=200`,
    },
    {
      link: `${ROUTE_BASE_PRESSREADER}/eahs`,
      title: `Revista tiempo de LUHHO`,
      img: `https://i.prcdn.co/img?cid=eahs&v=0&ver=0&width=200`,
    },
    {
      link: `${ROUTE_BASE_PRESSREADER}/eahh`,
      title: `Minería &amp; Energía`,
      img: `https://i.prcdn.co/img?cid=eahh&v=0&ver=0&width=200`,
    },
    {
      link: `${ROUTE_BASE_PRESSREADER}/eahg`,
      title: `Somos`,
      img: `https://i.prcdn.co/img?cid=eahg&v=0&ver=0&width=200`,
    },
    {
      link: `${ROUTE_BASE_PRESSREADER}/EAHN`,
      title: `Tecnología Minera`,
      img: `https://i.prcdn.co/img?cid=eahn&v=0&ver=0&width=200`,
    },
  ],
];
