import styled, {css} from "styled-components";
import { FontSize } from "../Typography/Typography";
import {Colors} from "../../_theme/color";

export enum TypeLink {
  Default = 'DEFAULT',
  Blue = 'BLUE',
  Gray = 'GRAY'
}

interface LinkProps {
  fontSize?: FontSize;
  typeLink?: TypeLink;
  bold?: boolean;
}

const TypeLinkStyle = (typeLink: TypeLink) => {
  switch (typeLink){
    case TypeLink.Blue:
      return css`
        color: ${Colors.BlueSky};

        :hover {
          text-decoration: underline;
        }
      `
    case TypeLink.Gray:
      return css`
        color: ${Colors.GrayScale1};

        :hover {
          color: ${Colors.Yellow} !important;
        }
      `
    case TypeLink.Default:
    default:
      return css`
        color: ${Colors.Black};

        :hover {
          color: ${Colors.Yellow} !important;
        }
      `
  }
}

const Link = styled.a<LinkProps>`
  cursor: pointer;
  font-size: ${({ fontSize = FontSize.Middle }) => fontSize};
  ${({ typeLink = TypeLink.Default }) => TypeLinkStyle(typeLink)}
  ${({ bold }) => bold ? css`font-weight: bold;` : ''}
  text-decoration: none;
`;

export default Link;
